<template>
<div>
    <v-dialog
        v-model="dialog"
        width="100%"
        scrollable
        > 
        <v-card :height="vsh-100" v-if="dialog"> 
            <v-toolbar color="primary" flat dark > 
                <v-list-item>
                    <v-list-item-avatar>
                        <v-icon size="40">mdi-clipboard-text-clock</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content v-if="JoinedDayShift">
                        <v-list-item-title>{{ MBS.actions.TEXT_UP(JoinedDayShift.outlet_name +" Sales Report")}} </v-list-item-title>
                        <v-list-item-subtitle>{{ JoinedDayShift.operating_date_ }}</v-list-item-subtitle> 
                    </v-list-item-content>
                </v-list-item> 
                <v-spacer></v-spacer>
                <v-btn  icon @click="dialog=false"><v-icon>close</v-icon></v-btn>
            </v-toolbar> 
            <mbs-page-tab 
                v-model="currentTab"
                :items="page_tabs"/>  

            <mbs-page-spinner v-if="downloading"
                :show="true"/> 
            <div v-else>   
                <mbs-item-manager v-if="true"
                    :add_vertical_lines="true"
                    v-model="input_table"
                    :row_click="true"
                    :show_select="false"
                    :row_click_action="false" 
                    :table_tabs="TableTabs"
                    :top_label="false"
                    :filter_date="false"
                    :filter_date_only_display="true"
                    :filter_date_value="'local_created_at'"
                    :filter_default_days="'90'"
                    :filters="FILTERS"
                    :name_items="currentTab?'Issued Receipt':(NAME_ITEMS)"
                    :name_item="NAME_ITEM"
                    :value_items="VALUE_ITEMS"
                    :value_item="VALUE_ITEM"
                
                    :header_items="HEADER_ITEMS" 
                    :data_items="TableData"   
                    :select_items="DataSource"   
                    :is_joined="true"  
                    
                    :total_item_values="TOTAL_ITEM_VALUES"   
        
                    :table_actions="TableAction" 
                    @SELECT_TABLE_ACTION="TABLE_ACTION"
                    @SELECT_ITEM_ACTION="ITEM_ACTION"
                />
            </div>
        </v-card>
    </v-dialog> 
    <div> 
        <div v-if="dialog_doc_show"> 
            <mbs-dialog-doc-show
                v-model="dialog_doc_show"
                :width="'600'"
                :title="input_show_doc.title"
                :data="input_show_doc.data"
                :data_key="input_show_doc.data_key"
                :document_type="input_show_doc.document_type"
                :height="input_show_doc.height"
                :top_header="input_show_doc.top_header"
                :bottom_header="input_show_doc.bottom_header"
            />  
        </div>
        <mbs-auto-print
            v-if="printing"
            @finish_print="FINISH_PRINT"
            :show="true"  
            :auto_print="auto_print"
            :auto_printer_name="auto_printer_name"
            :type="print_type" 
            :title="print_title" 
            :data="print_data" 
            />  
    </div>
</div>
     
</template> 
<script> 

    import DATA from "../../plugins/DATA";
    import DB from "../../plugins/DB";
    import {mapState} from "vuex" 
    import firebase from 'firebase/app' 
    import 'firebase/database'  

    const PAGE_NAME = "DAY-SHIFT"

    export default {
        props:['value','day_shift','cashup'],
        data(){
            return{        
                dialog:this.value, 
                page_tabs:[
                    {name:"Sold Items"},
                    {name:"Issued Receipts"},
                    {name:"orders"},
                ],
                NAME_ITEMS:"Sales Items",
                NAME_ITEM:"Sales Item",
                VALUE_ITEMS:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                VALUE_ITEM:DATA.ITEMS.DAYS_SHIFTS_SALES.value,
                currentTableTab:null,
                currentTab:1,
                filter_date:null,
                filteredDate:null, 
                input_table:null, 
                
               
                TOTAL_ITEM_VALUES:[ 
                    {name:'Total Items',value:'sold_quantity'},
                    {name:'Total Cost Price',value:'sold_cost_price',show_value:'sold_cost_price_',money:true},
                    {name:'Total Sales Price',value:'total_sold_price',show_value:'total_sold_price_',money:true},
                ],
                REF_DAYS_SHIFTS_SALES:null,
                // DaySales:null,
                downloading:false,
                dialog_doc_show:false,
                input_show_doc:{
                    // title:"INVOICE..",
                    top_header:true
                }, 

                printing:false,
                print_type:'sales_items',
                print_title:'SALES-REPORT',
                print_data:null,
                auto_print:false,
                auto_printer_name:null,
            }
        },
        created(){ 
            try {
                this.MBS.events.$on('ON_QR_SCAN', this.ON_QR_SCAN);   
                this.MBS.events.$on('MBS_KEYBOARD', this.MBS_KEYBOARD);  
               
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted(){   
            this.dialog = this.value
        },
        computed:{
            vs(){
                let vs = this.$vuetify.breakpoint 
                return vs
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
                K_CONNECTOR: state=> state.items['K_CONNECTOR'],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],

                Customers: state=> state.items[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()],
                PaymentMethods: state=> state.items[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                JoinedPaymentMethods: state=> state.join[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                DefaultPaymentMethod: state=> state.app[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()],
                Departments: state=> state.items[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()],
                JoinedDepartments: state=> state.join[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()], 

                DaysShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDaysShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],

                DaySales: state=> state.items[(DATA.ITEMS.DAY_SALES.values).toUpperCase()],
                JoinedDaySales: state=> state.join[(DATA.ITEMS.DAY_SALES.values).toUpperCase()],  
                DayOrders: state=> state.items[(DATA.ITEMS.DAY_ORDERS.values).toUpperCase()],
                JoinedDayOrders: state=> state.join[(DATA.ITEMS.DAY_ORDERS.values).toUpperCase()], 
                
               
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                
                LocalSelectedSalesItems: state=> state.locals[(DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values).toUpperCase()],
                JoinedLocalSelectedSalesItems: state=> state.join[(DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values).toUpperCase()], 
            }), 

            //data
            ItemsData(){
                const Items = this.Items
                const JoinedItems = this.JoinedItems
                const data = JoinedItems?JoinedItems:Items
                return data 
            },
            LocalSelectedSalesItemsData(){
                const Items = this.LocalSelectedSalesItems
                const JoinedItems = this.JoinedLocalSelectedSalesItems
                const data = JoinedItems?JoinedItems:Items
                return data 
            },
            ReversedLocalSelectedSalesItemsData(){   
                const items = this.LocalSelectedSalesItemsData 
                if(!items) {return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("selecting_list_key",true))
                return list.reverse()
            }, 
            PaymentMethodsData(){ 
                let DefaultPaymentMethod = this.DefaultPaymentMethod 
                let PaymentMethods = this.PaymentMethods
                let JoinedPaymentMethods = this.JoinedPaymentMethods
                let items = JoinedPaymentMethods?JoinedPaymentMethods:PaymentMethods 
                if(!items){return DefaultPaymentMethod}
                let list = [...DefaultPaymentMethod,...items]  
                return list
            },  
            DepartmentsData(){ 
                let Departments = this.Departments
                let JoinedDepartments = this.JoinedDepartments
                let items = JoinedDepartments?JoinedDepartments:Departments 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("name",true)) 
                return list
            },  
            CheckedItems(){
                const SELECTED_ITEMS = this.SELECTED_ITEMS
                if(!SELECTED_ITEMS){return null}
                let filtered = SELECTED_ITEMS.filter(item=>item.checked)
                return filtered
            },

            SELECTED_ITEMS(){
                const settings = this.CompanySettings
                const selling_list_descend = settings?settings.selling_list_descend:null
                const items = this.LocalSelectedSalesItemsData 
                const reversed = this.ReversedLocalSelectedSalesItemsData  
                return selling_list_descend?reversed:items
            },
            LocalCashup(){   
                let localCashup = this.MBS.actions.LocalCashup 
                return localCashup
            }, 
            TableTabs(){
                return  [
                    {name:"Items Per Transaction",show_if:null},
                    {name:"Aggregated Items",show_if:null},
                    // {name:"Over Paid Invoices",show_if:item=>item.balance<0},
                ]
            }, 
            TableAction(){
                let tab = this.currentTab
                let table_tab = this.currentTableTab
                let table_actions = [
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},    
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},    
                ]
                let table_actions2 = [
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",btn:true,icon:"mdi-printer",action:"print",color:"secondary", outlined:false,  text:"Print Sold Items"},   
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},    
                ]

                if (tab == 0) {
                    return table_actions2
                    if (table_tab==0) { 
                    }else{ 
                    }
                } else if (tab == 1) { 
                    return table_actions
                }else if (tab == 2) { 
                    return table_actions
                    if (table_tab == 0) { 
                    }else if(table_tab == 1) { 
                    }else if(table_tab == 2) { 
                    }  
                } 
            },

            

            moment(){  
                return this.MBS.date.moment
            }, 
            ITEM_FILTERS(){ 
                return [ 
                    {
                        type:"autocomplete",value:"transaction_key",barcode:true,prepend_inner_icon:'mdi-barcode',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Receipt Number",
                        select:{
                            name:'DaySales',
                            value:'receipt_number',
                            text:'receipt_number'
                        }, 
                        required:false,sm:12,md:12,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"department_code",prepend_inner_icon:'mdi-format-list-group',clearable:true,filled:false,multiple:true
                        ,chips:true,deletable_chips:true,label:"Department",
                        select:{
                            name:'Departments',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },
                    {
                        type:"autocomplete",value:"category_code",prepend_inner_icon:'mdi-shape-plus',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Category",
                        select:{
                            name:'Categories',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"item_code",search_value:"code",barcode:true,prepend_inner_icon:'mdi-barcode',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Barcode",
                        select:{
                            name:'Items',
                            value:'code',
                            text:'code'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"item_key",barcode:false,prepend_inner_icon:'mdi-tag-text',clearable:true,filled:false,
                        multiple:true,chips:true,deletable_chips:true,label:"Item Name",
                        select:{
                            name:'Items',
                            value:'item_key',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"cashup_key",barcode:true,prepend_inner_icon:'mdi-account-cash-outline',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Cashup",
                        select:{
                            name:'Cashups',
                            value:'key',
                            text:'operating_date_'
                        }, 
                        search:{
                            filter:(item, queryText, itemText) => { 
                                let index = (a,b)=>{
                                    if(!a||!b){return false}
                                    a = ""+a; b = ""+b
                                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                } 
                                let first = index(itemText,queryText)
                                let second = item?index(item.code,queryText):false
                                if(!item){return first} 
                                return first || second 
                            },
                            item_icon:{
                                name:'mdi-account-cash-outline',
                                tile:true
                            },
                            item_title:['till_name'],
                            item_subtitle:['teller_name','operating_date_']
                        },
                        required:false,sm:12,md:12,step_name:"Name"
                    },   
                       
                ]
            }, 
            TRANSACTION_FILTERS(){ 
                return [  
                    {
                        type:"autocomplete",value:"payment_method_name",prepend_inner_icon:'mdi-account-cash',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Payment",
                        select:{
                            name:'PaymentMethods',
                            value:'name',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"receipt_number",barcode:true,prepend_inner_icon:'mdi-barcode',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Receipt Number",
                        select:{
                            name:'DaySales',
                            value:'receipt_number',
                            text:'receipt_number'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"created_uid",barcode:false,prepend_inner_icon:'mdi-tag-text',clearable:true,filled:false,
                        multiple:true,chips:true,deletable_chips:true,label:"Teller Name",
                        select:{
                            name:'Tellers',
                            value:'uid',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"customer_key",barcode:false,prepend_inner_icon:'mdi-tag-text',clearable:true,filled:false,
                        multiple:true,chips:true,deletable_chips:true,label:"Customer",
                        select:{
                            name:'Customers',
                            value:'key',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"cashup_key",barcode:true,prepend_inner_icon:'mdi-account-cash-outline',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Cashup",
                        select:{
                            name:'Cashups',
                            value:'key',
                            text:'operating_date_'
                        }, 
                        search:{
                            filter:(item, queryText, itemText) => { 
                                let index = (a,b)=>{
                                    if(!a||!b){return false}
                                    a = ""+a; b = ""+b
                                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                } 
                                let first = index(itemText,queryText)
                                let second = item?index(item.code,queryText):false
                                if(!item){return first} 
                                return first || second 
                            },
                            item_icon:{
                                name:'mdi-account-cash-outline',
                                tile:true
                            },
                            item_title:['till_name'],
                            item_subtitle:['teller_name','key','local_created_at_']
                        },
                        required:false,sm:12,md:12,step_name:"Name"
                    },   
                      
                ]
            }, 
            FILTERS(){
                let tab = this.currentTab
                let table_tab = this.currentTableTab
                let ITEM_FILTERS = this.ITEM_FILTERS
                let TRANSACTION_FILTERS = this.TRANSACTION_FILTERS 
                let items = []
                if (tab == 0) {
                    if (table_tab==0) {
                        items = ITEM_FILTERS
                    }else{
                        items = ITEM_FILTERS
                    }
                    
                } else {
                    items = TRANSACTION_FILTERS
                }
                return items
            },
            
            TabItems(){ 
                return [
                    {name:"Items Per Transaction",show_if:null},
                    {name:"Aggregated Items",show_if:null},
                ]
            }, 
            TabReceipts(){ 
                return [
                    {name:"Items Per Transaction",show_if:null},
                    {name:"Aggregated Items",show_if:null},
                    // {name:"Over Paid Invoices",show_if:item=>item.balance<0},
                ]
            },
            TabOrders(){ 
                return [
                    {
                        name:"Unpaid Orders",
                        show_if:(this_,item)=>{
                            const deleted = item.deleted
                            const deleting_reason = item.deleting_reason !="sold"
                            return !deleted 
                        },
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}
                        }}
                    },
                    {name:"Paid order",show_if:(this_,item)=>{
                        const deleted = item.deleted
                        const deleting_reason = item.deleting_reason =="sold"
                        return deleted && deleting_reason
                    },
                    indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}
                        }}
                },
                    {name:"Deleted order",show_if:(this_,item)=>{
                        const deleted = item.deleted
                        const deleting_reason = item.deleting_reason !="sold"
                        return deleted && deleting_reason
                    },
                    indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}
                        }}
                },
                    // {name:"Over Paid Invoices",show_if:item=>item.balance<0},
                ]
            },
            
            TableTabs(){
                let tab = this.currentTab
                let table_tab = this.currentTableTab
                let TabItems = this.TabItems
                let TabReceipts = this.TabReceipts 
                let TabOrders = this.TabOrders 
                let items = []
                if(tab == 0) {
                    items = TabItems 
                }else if (tab == 1) {
                    items = null 
                }else if (tab == 2) {
                    items = TabOrders 
                } 
                return items
            },
            HEADER_ITEMS1(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",size:40,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false}, 
                    {id:0,name:"Barcode",value:'key',show:false},   
                    {id:0,name:"date",value:'local_created_at_',show:true},           
                    {id:0,name:"transaction",value:"transaction_key",show:true},     
                    {id:0,name:"Item",value:"name",show:true},     
                    {id:0,name:"quantity",value:'sold_quantity',align:'right',show:true},   
                    {id:0,name:"Discount",value:'sold_discount_',align:'right',show:true},   
                    {id:0,name:"Cost Price",value:'sold_cost_price_',align:'right',show:true},   
                    {id:0,name:"Sale Price",value:'total_sold_price_',align:'right',show:true},   
                    {id:0,name:"Created",value:'created_at_',show:false},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"..",action:"view",icon:"mdi-eyeee"}, 
                        // {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            }, 
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",size:40,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Barcode",value:'code',show:false},           
                    {id:0,name:"item",value:"name",show:true},     
                    {id:0,name:"Transactions",value:"number_of_transactions",align:'center',show:true},     
                    {id:0,name:"quantity",value:'sold_quantity',align:'center',show:true},   
                    {id:0,name:"Discount",value:'sold_discount_',align:'right',show:true},   
                    {id:0,name:"Cost Price",value:'sold_cost_price_',align:'right',show:true},   
                    {id:0,name:"Sale Price",value:'total_sold_price_',align:'right',show:true},   
                    {id:0,name:"Created",value:'created_at_',show:false},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"..",action:"view",icon:"mdi-eyeee"}, 
                        // {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            },
            HEADER_ITEMS3(){
                return [ 
                {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",size:40,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Date",value:'local_created_at_',show:true},   
                    {id:0,name:"Payment",value:"payment_method_name",show:true},  
                    {id:0,name:"Day Shift Code",value:'day_shift_key',show:false},   
                    {id:0,name:"Cashup Code",value:'cashup_key',show:false},      
                    {id:0,name:"Receipt No.",value:"receipt_number",align:"center",show:true},         
                    {id:0,name:"Outlet",value:"outlet_name",show:false},         
                    {id:0,name:"Teller",value:"teller_name",show:true},         
                    {id:0,name:"Till",value:"till_name",show:false},         
                    {id:0,name:"Customer",value:'customer_name',show:true},   
                    {id:0,name:"Payment",value:"payment_method",show:false},     
                    {id:0,name:"Items",value:'total_quantity_',align:'right',show:true},   
                    {id:0,name:"Cost Price",value:'total_cost_price_',align:'right',show:false},   
                    {id:0,name:"Discount",value:'all_discounts_',align:'right',show:true},   
                    {id:0,name:"Sold Price",value:'total_sold_price_',align:'right',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Sales Report",action:"view_order",icon:"mdi-eye"}, 
                        // {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                        // {name:"log",action:"log",icon:"mdi-plus"},   
                    ]}, 
                ]
            },
            HEADER_ORDERS(){
                return [ 
                {id:1,name:"General",title:true}, 
                    {id:0,name:"",icon:"mdi-cart",value:'icon_image',color:"primary",size:40,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Date",value:'local_created_at_',show:true},   
                    // {id:0,name:"Payment",value:"payment_method_name",show:true},  
                    {id:0,name:"Day Shift Code",value:'day_shift_key',show:false},   
                    {id:0,name:"Cashup Code",value:'cashup_key',show:false},      
                    {id:0,name:"Receipt No.",value:"receipt_number",align:"center",show:true},         
                    {id:0,name:"Outlet",value:"outlet_name",show:false},         
                    {id:0,name:"Teller",value:"teller_name",show:true},         
                    {id:0,name:"Till",value:"till_name",show:false},         
                    {id:0,name:"Customer",value:'customer_name',show:true},   
                    {id:0,name:"Payment",value:"payment_method",show:false},     
                    {id:0,name:"Items",value:'total_quantity_',align:'right',show:true},   
                    {id:0,name:"Cost Price",value:'total_cost_price_',align:'right',show:false},   
                    {id:0,name:"Discount",value:'all_discounts_',align:'right',show:true},   
                    {id:0,name:"Price",value:'total_sold_price_',align:'right',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Sales Report",action:"view_order",icon:"mdi-eye"}, 
                        // {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                        // {name:"log",action:"log",icon:"mdi-plus"},   
                    ]}, 
                ]
            },
            HEADER_DELETED_ORDERS(){
                return [ 
                {id:1,name:"General",title:true}, 
                    {id:0,name:"",icon:"mdi-cart",value:'icon_image',color:"primary",size:40,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Date",value:'local_created_at_',show:true},    
                    {id:0,name:"Day Shift Code",value:'day_shift_key',show:false},   
                    {id:0,name:"Cashup Code",value:'cashup_key',show:false},      
                    {id:0,name:"Receipt No.",value:"receipt_number",align:"center",show:true},         
                    {id:0,name:"Outlet",value:"outlet_name",show:false},         
                    {id:0,name:"Teller",value:"teller_name",show:true},         
                    {id:0,name:"Till",value:"till_name",show:false},         
                    {id:0,name:"Customer",value:'customer_name',show:true},   
                    {id:0,name:"Payment",value:"payment_method",show:false},     
                    {id:0,name:"Items",value:'total_quantity_',align:'right',show:true},   
                    {id:0,name:"Cost Price",value:'total_cost_price_',align:'right',show:false},   
                    {id:0,name:"Discount",value:'all_discounts_',align:'right',show:false},   
                    {id:0,name:"Price",value:'total_sold_price_',align:'right',show:true},   
                    {id:0,name:"Reason",value:'deleting_reason',align:'center',show:true},   
                    {id:0,name:"Description",value:'description',align:'center',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Sales Report",action:"view_order",icon:"mdi-eye"}, 
                        // {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                        // {name:"log",action:"log",icon:"mdi-plus"},   
                    ]}, 
                ]
            },
            HEADER_ITEMS(){
                let tab = this.currentTab
                let table_tab = this.currentTableTab
                if (tab == 0) {
                    if (table_tab==0) {
                        return this.HEADER_ITEMS1
                    }else{
                        return this.HEADER_ITEMS2
                    }
                } else if (tab == 1) { 
                    return this.HEADER_ITEMS3 
                }else if (tab == 2) { 
                    if (table_tab == 0) {
                        return this.HEADER_ORDERS   
                    }else if(table_tab == 1) {
                        return this.HEADER_ORDERS  
                    }else if(table_tab == 2) {
                        return this.HEADER_DELETED_ORDERS  
                    }  
                } 
            }, 
            TableData(){
                let tab = this.currentTab
                let table_tab = this.currentTableTab
                let day_sales = this.DaySalesData
                let day_orders = this.DayOrdersData
                let per_transactions = this.PerTransactionItems
                let aggregated = this.AggregatedItems
                let items = null
                if (tab == 0) {
                    if (table_tab==0) {
                        items = per_transactions
                    }else{
                        items = aggregated
                    } 
                }else if (tab == 1) {
                    items = day_sales
                }else if (tab == 2) {
                    items = day_orders
                } 
                console.log(items,'TableData.....-.d'); 
                return items
            }, 
            DaySalesData(){ 
                let cashup = this.cashup
                let cashup_key = cashup?cashup.key:null
                let DaySales = this.DaySales
                let JoinedDaySales = this.JoinedDaySales
                let items = JoinedDaySales?JoinedDaySales:DaySales  
                if(!items){return null}
                let list = [...items] 
                let filtered = list.filter(item=>{
                    return item.cashup_key+"" == cashup_key+""
                }) 
                return cashup_key?filtered:list
            }, 
            DayOrdersData(){ 
                let cashup = this.cashup
                let cashup_key = cashup?cashup.key:null
                let DayOrders = this.DayOrders
                let JoinedDayOrders = this.JoinedDayOrders
                let items = JoinedDayOrders?JoinedDayOrders:DayOrders  
                if(!items){return null}
                let list = [...items] 
                let filtered = list.filter(item=>{
                    return item.cashup_key+"" == cashup_key+""
                }) 
                return cashup_key?filtered:list
            }, 
            // DaySalesData(){ 
            //     let DaySales = this.DaySales
            //     console.log(DaySales,'DaySales..........');
            //     let JoinedDaySales = this.MBS.actions.JOIN_SALE_ORDERS(DaySales)
            //     let items = JoinedDaySales?JoinedDaySales:DaySales 
            //     if(!items){return null}
            //     let list = [...items]
            //     list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
            //     console.log(list,'l***********');
            //     return list
            // }, 
            AggregatedItems(){
                let SaleOrders = this.DaySalesData 
                let filteredDate = this.filteredDate

                const items = this.MBS.actions.CALCULATE_SALES_AGGREGATED_ITEMS({
                    sale_orders:SaleOrders,
                    filtered_date:filteredDate,
                })
                
                return items
                let moment = this.moment
                let difference = filteredDate?filteredDate.difference:null
                let to_date = filteredDate?filteredDate.to_date:null
                let from_date = filteredDate?filteredDate.from_date:null
                if(!SaleOrders){return null}
                // let items = []
                SaleOrders.forEach(order => {
                    // let date = moment(order.local_created_at).format("yyyy-MM-D")
                    // let momentDate = moment(date) 
                    // let isBetween = momentDate.isBetween(from_date,to_date,'','[]')  
                    let isBetween = true
                    if (isBetween) {
                        let sold_unjoined_items = order.items
                        let sold_joined_items = order.joined_items
                        let sold_items = sold_joined_items?sold_joined_items:sold_unjoined_items 
                        let total_discount = this.toNumber(order.total_discount)
                        let item_size = this.MBS.actions.SIZE(sold_items)
                        let average_discount = total_discount/item_size
                        if (sold_items) {
                            sold_items.forEach(item => { 
                                let index = items.findIndex(it=>{
                                    return it.item_key  == item.item_key
                                }) 
                                let sold_quantity = this.toNumber(item.quantity)
                                let sold_cost_price = this.toNumber(item.sold_cost_price)*sold_quantity
                                let sold_discount = (this.toNumber(item.discount)*sold_quantity)+average_discount
                                let sold_price = this.toNumber(item.sold_price)
                                let total_sold_price = (sold_price*sold_quantity)-sold_discount

                                if (index == -1) { 
                                    items.push({
                                        ...item, 
                                        sold_quantity:sold_quantity,
                                        sold_cost_price:sold_cost_price,
                                        sold_discount:sold_discount,
                                        sold_price:sold_price,
                                        total_sold_price:total_sold_price,
                                        number_of_transactions:1,
                                        
                                        sold_quantity_:this.MBS.actions.money( sold_quantity),
                                        sold_cost_price_:this.MBS.actions.money( sold_cost_price),
                                        sold_discount_:this.MBS.actions.money( sold_discount),
                                        sold_price_:this.MBS.actions.money( sold_price),
                                        total_sold_price_:this.MBS.actions.money( total_sold_price),
                                    })
                                } else {
                                    items[index].sold_quantity = items[index].sold_quantity+sold_quantity
                                    items[index].sold_cost_price = items[index].sold_cost_price+sold_cost_price
                                    items[index].sold_discount = items[index].sold_discount+sold_discount
                                    items[index].sold_price = items[index].sold_price+sold_price
                                    items[index].total_sold_price = items[index].total_sold_price+total_sold_price
                                    items[index].number_of_transactions = items[index].number_of_transactions+1
                                    
                                    items[index].sold_quantity_ = this.MBS.actions.money( items[index].sold_quantity)
                                    items[index].sold_cost_price_ = this.MBS.actions.money( items[index].sold_cost_price)
                                    items[index].sold_discount_ = this.MBS.actions.money( items[index].sold_discount)
                                    items[index].sold_price_ = this.MBS.actions.money( items[index].sold_price)
                                    items[index].total_sold_price_ = this.MBS.actions.money( items[index].total_sold_price)
                                }    
                            });
                            
                        }
                    }     
                });  
                return items
            },
            PerTransactionItems(){ 
                let SaleOrders = this.DaySalesData
                let filteredDate = this.filteredDate
                const items = this.MBS.actions.CALCULATE_SALES_ITEMS_PER_TRANSACTION({
                    sale_orders:SaleOrders,
                    filtered_date:filteredDate,
                })
                return items
                let moment = this.moment
                let difference = filteredDate?filteredDate.difference:null
                let to_date = filteredDate?filteredDate.to_date:null
                let from_date = filteredDate?filteredDate.from_date:null
                if(!SaleOrders){return null}
                // let items = []
                SaleOrders.forEach(order => {  
                    // let date = moment(order.local_created_at).format("yyyy-MM-D")
                    // let momentDate = moment(date) 
                    // let isBetween = momentDate.isBetween(from_date,to_date,'','[]')  
                    let isBetween = true 
                    if (isBetween) {
                        let sold_unjoined_items = order.items
                        let sold_joined_items = order.joined_items
                        let sold_items = sold_joined_items?sold_joined_items:sold_unjoined_items 
                        let total_discount = this.toNumber(order.total_discount)
                        let item_size = this.MBS.actions.SIZE(sold_items)
                        let average_discount = total_discount/item_size
                        if (sold_items) {
                            sold_items.forEach(item => { 
                                // let index = items.findIndex(it=>{
                                //     return it.item_key  == item.item_key
                                // }) 
                                let sold_quantity = this.toNumber(item.quantity)
                                let sold_cost_price = this.toNumber(item.sold_cost_price)*sold_quantity
                                let sold_discount = (this.toNumber(item.discount)*sold_quantity)+average_discount
                                let sold_price = this.toNumber(item.sold_price)
                                let total_sold_price = (sold_price*sold_quantity)-sold_discount
                                

                                items.push({
                                    ...item, 
                                    sold_quantity:sold_quantity,
                                    sold_cost_price:sold_cost_price,
                                    sold_discount:sold_discount,
                                    sold_price:sold_price,
                                    total_sold_price:total_sold_price,
                                    
                                    sold_quantity_:this.MBS.actions.money( sold_quantity),
                                    sold_cost_price_:this.MBS.actions.money( sold_cost_price),
                                    sold_discount_:this.MBS.actions.money( sold_discount),
                                    sold_price_:this.MBS.actions.money( sold_price),
                                    total_sold_price_:this.MBS.actions.money( total_sold_price),
                                    
                                    transaction_key:order.key,

                                    local_created_at:order.local_created_at,
                                    local_created_at_:order.local_created_at_,
                                    local_created_at_date:order.local_created_at_date,
                                    local_created_at_time:order.local_created_at_time,
                                })
                                     
                            });
                            
                        }
                    }     
                });   
                return items
            },
            CalculatedItems(){
                let tap = this.currentTableTab
                if (tap==0) {
                    return this.PerTransactionItems
                }else{
                    return this.AggregatedItems
                }
            },
            DayShiftsCashups(){ 
                let joined_day = this.JoinedDayShift 
                if(!joined_day){return null}
                
                let cashups = joined_day?joined_day.joined_cashups:null
                if(!cashups){return null} 
                let list = [...cashups]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))    
                return list 
            },

            JoinedDayShift(){
                const day_shift = this.day_shift 
                let joined_day = this.MBS.actions.JOIN_DAY_SHIFT(day_shift)
                return joined_day
                // if(!day_shift){return null}
                // const res_day_shift  = await this.$store.dispatch("join_day_shift",{
                //     data:day_shift
                // }) 
                // const data =  res_day_shift?.data
                // console.log(data,'data...............');
                // return data
            }, 
            Users(){
                let items  =this.$store.getters.getCompanyUsers
                let list = items?[...items]:null
                if(!list){return null}
                list.sort(this.MBS.actions.dynamicSort('created_at',false))
                return list
            }, 
            JoinedUsers(){
                let users = this.Users
                let join = this.MBS.actions.JOIN_USERS(users)
                return join
            }, 
            DataSource(){ 
                let Customers = this.Customers
                let PaymentMethodsData = this.PaymentMethodsData
                let Departments = this.DepartmentsData
                let Outlets = this.OutletsData
                let DayShifts = this.DayShiftsData
                let Cashups = this.DayShiftsCashups
                let DaySales = this.DaySalesData
                let DayOrders = this.DayOrdersData
                let Tellers = this.JoinedUsers 
                let Categories = this.ItemCategories 
                return { 
                    Customers:Customers,
                    PaymentMethods:PaymentMethodsData,
                    Departments:Departments,
                    Outlets:Outlets,
                    DayShifts:DayShifts,
                    Cashups:Cashups,
                    DaySales:DaySales,
                    DayOrders:DayOrders,
                    Tellers:Tellers,
                    Categories:Categories,
                }
            },
              
        },
        methods:{ 
            TABLE_ACTION(action,item){
                try { 
                    if (action == "refresh") { 
                        this.FI_INITIATE()
                    }else if (action == "print") { 
                        this.PRINT_ITEMS()
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try {  
                    let tab = this.currentTab
                    let table_tab = this.currentTableTab
                    let doc_title = null
                    let doc_type = null
                    if (tab == 0) {
                        doc_type = "item"
                    }else if(tab == 1) {
                        if (item?.account_type == "on_account") {
                            doc_title = "INVOICE"
                            doc_type = "invoice"
                        } else {
                            doc_title = "RECEIPT"
                            doc_type = "receipt" 
                        }
                    }else if(tab == 2) {
                        doc_type = "bill"
                        if (table_tab == 0) {
                            doc_title = "UNPAID BILL"
                        }else if(table_tab == 1) {
                            doc_title = "PAID BILL"
                        }else if(table_tab == 2) {
                            doc_title = "DELETED BILL"
                        }
                    } 

                    if (action == "view_order") { 
                        if (doc_title) { 
                            this.SHOW_DIALOG_DOCUMENT({
                                data:item,
                                doc_title:doc_title,
                                doc_type:doc_type,
                            })
                        }
                    }else if (action == "on_row_clicked") { 
                        if (doc_title) { 
                            this.SHOW_DIALOG_DOCUMENT({
                                data:item,
                                doc_title:doc_title,
                                doc_type:doc_type,
                            })
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            async FI_INITIATE(){ 
                try {
                    let day_shift = this.day_shift
                    let day_shift_key = day_shift?day_shift.key:null
                    if (day_shift_key) {
                        this.downloading = true
                        let path_sales = this.MBS.actions.COMPANY_LINK(DATA.PATHS.days_shifts_sales+day_shift_key,true)
                        let path_orders = this.MBS.actions.COMPANY_LINK(DATA.PATHS.item_orders+day_shift_key,true)
                        console.log(path_sales,'path_sales....... .......***');
                        console.log(path_orders,'path_orders....... .......***');

                        await this.$store.dispatch("fi_get_day_sales",{
                            path:path_sales
                        })  
                        this.$store.dispatch("fi_get_day_orders",{
                            path:path_orders
                        }).then(res=>{
                            this.downloading = false
                            console.log(res,'res....FI_INITIATE.....');
                        }).catch(error=>{
                            this.downloading = false
                            console.log(error,'error.....FI_INITIATE.......');
                        })
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FI_INITIATE',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
            FI_CLOSE(){ 
                try { 
                    this.$store.dispatch("off_refs",{
                        name:DATA.ITEMS.DAY_SALES.values
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FI_CLOSE',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            
            async PRINT_ITEMS(){ 
                try { 
                    let tab = this.currentTab
                    let table_tab = this.currentTableTab
                    let day_sales = this.DaySalesData
                    let all_items = this.TableData
                    let input_table = this.input_table
                    let filtered_items = input_table?.input_filters?.data_filtered
                    let items = filtered_items?filtered_items:all_items

                    let per_transactions = this.PerTransactionItems
                    let aggregated = this.AggregatedItems
                    let REQUEST = await this.MBS.actions.EPSON_DAY_ITEM_REQUEST({ 
                        data:{
                            day_shift:day_sales,
                            sale_orders:day_sales,
                            aggregated_items:items
                        },
                        report_type:"DDDDDD"
                    })

                    console.log(REQUEST,'REQUEST....i..');
                    console.log(items,'items....i..');
  
                    let printer = this.CurrentReceiptPrinter
                    let printer_name = printer?printer.name:null
                    let printer_type_name = printer?printer.printer_type_name:null
                    
                    let day_shift = this.JoinedDayShift 
                    this.printing = false
                    setTimeout(() => {
                        if (printer_type_name) {
                            // this.auto_print = true
                        }
                        this.auto_printer_name = printer_name
                        this.print_data = {
                            day_shift:day_shift,
                            sale_orders:day_sales,
                            aggregated_items:items
                        } 
                        this.printing = true   
                    }, 5); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PRINT_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
            },
            FINISH_PRINT(action,payload){
                try {   
                    console.log(action,'action....');
                    console.log(payload,'payload....');
                    let canceled = payload?payload.canceled:null
                    if(!action){return}
                    if (action.code == PAGE_NAME+"=CLOSE-CASHUP") {
                        this.$store.dispatch("fi_upload_cashups")
                        // this.$store.dispatch("fi_uploadSales")
                        this.SUBMIT_CLOSE_CASHUP(action.data) 
                        this.REFRESH_DATA()  
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FINISH_PRINT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            SHOW_DIALOG_DOCUMENT(payload){
                try {
                    let data = {}
                    let doc_type = payload?.doc_type
                    let doc_title = payload?.doc_title
                    if(payload?.data){
                        data = payload?.data
                    }
                    if(payload?.key){
                        data.key = payload?.key
                    }
                    if(!this.input_show_doc){
                        this.input_show_doc = {}
                    } 
                    if(data=={}){return null}
                    if (doc_type) {
                        console.log(doc_type,'doc_type/.................');
                    }

                    this.input_show_doc.data = {...data,merge_doc_type:"receipt"}
                    this.input_show_doc.data_key = data.key
                    this.input_show_doc.title = doc_title
                    this.input_show_doc.document_type = doc_type?doc_type:"invoice"
                    this.dialog_doc_show = true  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SHOW_DIALOG_DOCUMENT',
                        page:PAGE_NAME, 
                    }) 
                }

            },
            
            //---------------[MAIN FUNCTIONS]------------   
            ON_QR_SCAN(code){
                try {
                    if (code) {  
                        
                        let size = this.MBS.actions.SIZE(code)
                        if (size<3) { 
                        }else{  
                             
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            MBS_KEYBOARD(key){ 
                let focused_field = this.focused_field
                const field_value = focused_field?focused_field.value:null

                const ref_inputs = this.$refs
                if (key ) {
                    let value = key.value 
                    if (field_value) { 
                        if (field_value == "current_selected_item_quantity") {
                            if (value=="BACK_SPACE") {
                                this.current_selected_item.quantity = (""+this.current_selected_item.quantity).slice(0,-1)
                            } else {
                                this.current_selected_item.quantity =this.current_selected_item.quantity+""+value 
                            }
                        }else if (field_value == "current_selected_item_discount") {
                            if (value=="BACK_SPACE") {
                                this.current_selected_item.discount = (""+this.current_selected_item.discount).slice(0,-1)
                            } else {
                                this.current_selected_item.discount =this.current_selected_item.discount+""+value 
                            }
                        }else{
                            if(!this.input){this.input={}}
                            if (value=="BACK_SPACE") {
                                this.input[field_value] =(this.input[field_value]?this.input[field_value]+'':'').slice(0,-1)
                            }else{
                                this.input[field_value] =(this.input[field_value]?this.input[field_value]:'')+value
                                console.log((this.input[field_value]?this.input[field_value]:'')+value,'****......');
                            }
                            setTimeout(() => {
                                this.input = {...this.input}
                            }, 1);

                            console.log(this.input,'input......');
                            console.log(value,'value......');
                        }
                        
                    }
                }
            }, 
        },
        beforeDestroy(){
            try {
                this.MBS.events.$off('ON_QR_SCAN', this.ON_QR_SCAN);  
                this.MBS.events.$off('MBS_KEYBOARD', this.MBS_KEYBOARD);    
                this.FI_CLOSE()  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch:{ 
            item_multiple_select(value){
                this.$emit("item_multiple_select",value?true:false)
            },
            CheckedItems(value){
                this.$emit("checked_items",value)   
            },
            SELECTED_ITEMS(value){
                 
            },
            dialog(value){
                this.$emit('input', value)
                if (value) {
                    this.FI_INITIATE()
                    console.log('opened**********');
                } else {
                    this.FI_CLOSE()
                    console.log('closed**********');
                }
            },
            value(value){
                this.dialog = value
            },
            input_table(value){ 
                if (value) {
                    this.filteredDate = value.filter_date
                    this.currentTableTab = value.current_table_tab
                } 
            } 
        } 

    }
</script> 